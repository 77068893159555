import React from 'react'
import PropTypes from 'prop-types'
import './StandardButton.scss'
import { Button } from 'react-bootstrap'
import ConcurGlyphicon from './ConcurGlyphicon'

const spinner = <ConcurGlyphicon style={{ margin: '2px 0 0 2px' }} className='fa-spin' glyph='spinner' />
const StandardButton = ({ onClick, id, text, className, glyph, btnType, type, disabled, isLoading }) => {
  const btnClassName = 'standard-btn' + ' ' + className + ' ' + btnType
  return (
    <Button
      type={type || 'button'}
      className={btnClassName}
      disabled={disabled || false}
      onClick={onClick}
      id={id && id}
      name={id || `standart-btn-${text && text}`}
    >
      {glyph &&
        <ConcurGlyphicon glyph={glyph} />
      }
      {text &&
        <span>{text}</span>
      }
      {isLoading && spinner}
    </Button>
  )
}

StandardButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  isLoading: PropTypes.bool,
  glyph: PropTypes.string,
  className: PropTypes.string,
  btnType: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string
}

export default React.memo(StandardButton)
