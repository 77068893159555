import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import './AutoLinkWarningModal.scss'
import StandardButton from 'components/StandardButton'

const AutoLinkWarningModal = React.memo((props) => {
  const { autolinkHref, show, close } = props
  const platformUri = window.location.hostname
  const openAutolink = () => {
    window.open(autolinkHref, '_blank')
    close()
  }
  return (
    <Modal
      className='capture-modal' dialogClassName='autolink-warning-modal' backdrop='static'
      show={show} onHide={close}
    >
      <Modal.Header>Warning: External Link</Modal.Header>
      <Modal.Body>
        <p>The link clicked will take you outside of {platformUri}</p>
        <p>Only proceed if you trust the source of this link:</p>
        <p>{autolinkHref}</p>
      </Modal.Body>
      <Modal.Footer>
        <StandardButton
          className='cancel-open-autolink'
          onClick={close}
          text='Cancel'
          btnType='secondary'
        />
        <StandardButton
          className='open-autolink'
          onClick={openAutolink}
          glyph='start'
          text='Open in a new tab'
          btnType='primary'
        />
      </Modal.Footer>
    </Modal>
  )
})
AutoLinkWarningModal.propTypes = {
  autolinkHref: PropTypes.string,
  show: PropTypes.bool,
  close: PropTypes.func
}

export const useAutolinkWarningModal = () => {
  const [showAutolinkWarning, setShowAutolinkWarning] = useState(false)
  const [autolinkHref, setAutolinkHref] = useState('')

  function openAutolinkWarningModal (e) {
    if (e) {
      e.preventDefault()
      setAutolinkHref(e.target.href)
      setShowAutolinkWarning(true)
    }
  }

  function closeAutolinkWarningModal () {
    setShowAutolinkWarning(false)
    setAutolinkHref('')
  }

  return [
    <AutoLinkWarningModal
      autolinkHref={autolinkHref}
      show={showAutolinkWarning}
      close={closeAutolinkWarningModal}
    />,
    openAutolinkWarningModal
  ]
}
