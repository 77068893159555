import React from 'react'
import PropTypes from 'prop-types'
import Spinner from 'react-spinkit'

const Loader = ({ text }) => (
  <div style={{ margin: '2em auto', 'textAlign':'center' }} className='loader'>
    <Spinner style={{ marginLeft: '-10px' }} name='line-scale' color='#3498db' />
    {`${text || 'Loading'}...`}
  </div>
)
Loader.propTypes = {
  text: PropTypes.string
}
export default Loader
