import React from 'react'
import PropTypes from 'prop-types'
import './Header.scss'
import SanitizedHtml from '../SanitizedHtml'

class Header extends React.PureComponent {
  render () {
    const { name, headerRest } = this.props
    return (
      <div id='headerRoot' className='text-center header text-primary'>
        <div className='header-title'><SanitizedHtml text={name} /></div>
        {headerRest}
      </div>)
  }
}

Header.propTypes = {
  name: PropTypes.string,
  headerRest: PropTypes.any
}

export default Header
