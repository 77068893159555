import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'
import createDOMPurify from 'dompurify'
import { trimRichFormatText } from '../routes/CaptureForm/components/questions/QuestionDetails'
import Interweave from 'interweave'
import { UrlMatcher, EmailMatcher } from 'interweave-autolink'
import { useAutolinkWarningModal } from './AutolinkWarningModal'
const DOMPurify = createDOMPurify(window)

const SanitizedHtml = ({ text, ...rest }) => {
  const [ AutolinkWarningModal, openAutolinkWarningModal ] = useAutolinkWarningModal()
  const isGlobalLinkifyEnabled = useSelector(state => state.systemPreferences.isGlobalLinkifyEnabled, shallowEqual)
  const matchers = useMemo(() => isGlobalLinkifyEnabled ? [new EmailMatcher('email'), new UrlMatcher('url')]
    : [], [isGlobalLinkifyEnabled])
  if (!text) {
    return ''
  }
  const trimmedText = trimRichFormatText(text)
  const cleanText = DOMPurify.sanitize(trimmedText)
  if (isGlobalLinkifyEnabled) {
    return (
      <Fragment>
        <Interweave
          {...rest}
          content={cleanText}
          matchers={matchers}
          newWindow
          onClick={openAutolinkWarningModal}
        />
        {AutolinkWarningModal}
      </Fragment>
    )
  }
  return <span {...rest} dangerouslySetInnerHTML={{ __html: cleanText }} />
}

SanitizedHtml.propTypes = {
  text: PropTypes.string
}

export default SanitizedHtml
