import React from 'react'
import PropTypes from 'prop-types'

const Glyphicon = ({ style, glyph, className, ...rest }) => {
  style = Object.assign({
    width: '16px',
    height: '16px'
  }, style)
  if (style.color) {
    style.fill = style.color
  }
  return (
    <svg
      className={className + ` icon icon-${glyph}`}
      style={style}
      {...rest}>
      <use xlinkHref={`#icon-${glyph}`} />
    </svg>
  )
}

Glyphicon.propTypes = {
  style: PropTypes.object,
  glyph: PropTypes.string,
  className: PropTypes.string
}

export default Glyphicon
