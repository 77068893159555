import React, { useState, useEffect } from 'react'
import './ValidationMessage.scss'
import PropTypes from 'prop-types'

export function ValidationMessage (props) {
  const [isShowError, setIsShowError] = useState(false)
  useEffect(() => {
    setIsShowError(props.isValidate && !props.isValid && props.isRequired)
  }, [props.isValidate, props.isValid, props.isRequired])
  return (
    !props.isHideValidation &&
    <span className={isShowError ? 'error-message validation-message' : 'validation-message'}>
({props.isRequired ? 'required' : 'optional'})
    </span>
  )
}

ValidationMessage.propTypes = {
  isHideValidation: PropTypes.bool,
  isRequired: PropTypes.bool,
  isValidate: PropTypes.bool,
  isValid: PropTypes.bool
}
