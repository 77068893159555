import React from 'react'
import { Modal } from 'react-bootstrap'
import StandardButton from 'components/StandardButton'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './RequiredWarningModal.scss'

const RequiredWarningModal = React.memo(function RequiredWarningModal (props) {
  const {
    show,
    dismiss,
    modalText = '',
    modalHeader = 'Warning',
    className = ''
  } = props
  let dialogClassName = classNames('required-warning-modal', className)
  return (
    <Modal
      dialogClassName={dialogClassName}
      className='capture-modal'
      backdrop='static'
      show={show}
      onHide={dismiss}
    >
      <Modal.Header>{modalHeader}</Modal.Header>
      <Modal.Body>
        <div className='required-warning-modal error-text'>
          {modalText}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <StandardButton
          onClick={dismiss}
          text='OK'
          btnType='primary'
        />
      </Modal.Footer>
    </Modal>
  )
})

RequiredWarningModal.propTypes = {
  show: PropTypes.bool,
  dismiss: PropTypes.func,
  modalText: PropTypes.string,
  modalHeader: PropTypes.string,
  className: PropTypes.string
}

export default RequiredWarningModal
